<template>
  <svg viewBox="0 0 16 16">
    <path
      d="M2 0C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2L0 14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H14C14.5304 16 15.0391 15.7893 15.4142 15.4142C15.7893 15.0391 16 14.5304 16 14V2C16 1.46957 15.7893 0.960859 15.4142 0.585786C15.0391 0.210714 14.5304 0 14 0L2 0ZM12.03 4.97C12.1689 5.10875 12.2479 5.29644 12.2499 5.49275C12.252 5.68907 12.177 5.87837 12.041 6.02L8.049 11.01C7.98039 11.0839 7.89758 11.1432 7.80553 11.1844C7.71348 11.2255 7.61407 11.2477 7.51325 11.2496C7.41243 11.2514 7.31227 11.233 7.21876 11.1952C7.12524 11.1575 7.0403 11.1013 6.969 11.03L4.324 8.384C4.25031 8.31534 4.19121 8.23254 4.15022 8.14054C4.10923 8.04854 4.08718 7.94923 4.08541 7.84852C4.08363 7.74782 4.10216 7.64779 4.13988 7.5544C4.1776 7.46101 4.23374 7.37618 4.30496 7.30496C4.37618 7.23374 4.46101 7.1776 4.5544 7.13988C4.64779 7.10216 4.74782 7.08363 4.84852 7.08541C4.94923 7.08718 5.04854 7.10923 5.14054 7.15022C5.23254 7.19121 5.31534 7.25031 5.384 7.324L7.478 9.417L10.951 4.992C11.0195 4.91797 11.1022 4.85851 11.1942 4.81717C11.2861 4.77583 11.3855 4.75347 11.4863 4.75141C11.5871 4.74936 11.6873 4.76766 11.7809 4.80522C11.8745 4.84277 11.9596 4.89882 12.031 4.97H12.03Z"
    />
  </svg>
</template>

<script>
export default {
  name: "CheckboxIcon",
};
</script>
